import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Header.less';
import hamburger from './hamburger.svg';

interface HeaderProps {
  setShowSidebar: (show: boolean) => void;
}
const Header = ({ setShowSidebar }: HeaderProps) => {
  useStyles(s);

  return (
    <div className={s.root}>
      <div className="container">
        <div className="row g-0 align-items-center">
          <div className="col-auto d-lg-none">
            <button
              type="button"
              className={s.hamburgerBtn}
              onClick={() => {
                if (typeof setShowSidebar === 'function') setShowSidebar(true);
              }}
            >
              <img src={hamburger} className={s.hamburgerIcon} alt="Menu" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
