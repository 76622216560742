import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader-react18/withStyles';
import s from './Banner.less';

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    success: PropTypes.bool,
    info: PropTypes.bool,
    warning: PropTypes.bool,
    danger: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    success: false,
    info: false,
    warning: false,
    danger: false,
  };

  render() {
    const {
      children,
      className,
      success,
      info,
      warning,
      danger,
      ...otherProps
    } = this.props;

    return (
      <div
        className={cx(s.root, className, {
          [s.success]: success,
          [s.info]: info,
          [s.warning]: warning,
          [s.danger]: danger,
        })}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(s)(Banner);
