import cx from 'classnames';
import Link from 'components/Link/Link';
import useProfile from 'customer/components/iam/Profile/hooks';
import useKycProfile from 'customer/components/kyc/Profile/hooks';
import GnosisPromotion from 'customer/components/promotions/GnosisPayReferral/SidebarCard';
import { useAppContext } from 'customer/context';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React, { useCallback, useEffect } from 'react';
import history from 'utils/history';
import { isBasePath } from 'utils/url';
import useLogin from '../../iam/Login/hooks';
import { isApproved } from '../../kyc/Profile/util';
import s from './Sidebar.less';
import logo from './logo.svg';

interface Props {
  setShowSidebar: (show: boolean) => void;
  isVerified?: boolean;
}

export const SidebarComponent = ({ setShowSidebar, isVerified }: Props) => {
  useStyles(s);
  const {
    isSandbox,
    pathname,
    isKycRequired,
    isInviteCodeDisabled,
    isFeatureGnosisPayPromotion,
  } = useAppContext();
  const { isLoginSuccess, isLoginFailure, isLogoutSuccess } = useLogin();
  const { profileId } = useProfile();
  const { profile, read } = useKycProfile();

  useEffect(() => {
    if (profileId && profile?.id !== profileId) read(profileId);
  }, [profileId]);

  const handleSelect = useCallback(
    (link: string) => {
      if (typeof setShowSidebar === 'function') setShowSidebar(false);
      if (history) history.push(link);
    },
    [setShowSidebar, history],
  );
  const handleKeyPress = useCallback((link: string, e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e?.key === 'Enter') {
      handleSelect(link);
    }
    return false;
  }, []);

  const handleClick = useCallback((link: string, e: React.MouseEvent) => {
    e.preventDefault();
    handleSelect(link);
    return false;
  }, []);

  const isLoadingCompleted =
    isLoginSuccess || isLoginFailure || isLogoutSuccess;

  return (
    <div className={s.root}>
      <div className={s.topMenu}>
        <Link className={s.homeBtn} to="/">
          <img src={logo} className={s.homeIcon} alt="Monerium" />
          {isSandbox && <span>SANDBOX</span>}
        </Link>
        {isVerified && (
          <>
            <a
              tabIndex={0}
              href="/accounts"
              onKeyUp={(e) => handleKeyPress('/accounts', e)}
              onClick={(e) => handleClick('/accounts', e)}
              className={cx(s.link, {
                [s.active]: isBasePath(pathname, '/accounts'),
              })}
            >
              <div className={s.linkContainer}>
                <i className="material-icons">account_balance_wallet</i>
                <span>Accounts</span>
              </div>
            </a>
            <a
              tabIndex={0}
              href="/profiles"
              role="button"
              onKeyUp={(e) => handleKeyPress('/profiles', e)}
              onClick={(e) => handleClick('/profiles', e)}
              className={cx(s.link, {
                [s.active]: isBasePath(pathname, '/profiles'),
              })}
            >
              <div className={s.linkContainer}>
                <i className="material-icons">person</i>
                <span>My profile</span>
              </div>
            </a>
            {!isInviteCodeDisabled &&
              isKycRequired &&
              profile &&
              isApproved(profile) && (
                <a
                  tabIndex={0}
                  href="/invitecodes"
                  onKeyUp={(e) => handleKeyPress('/invitecodes', e)}
                  onClick={(e) => handleClick('/invitecodes', e)}
                  className={cx(s.link, {
                    [s.active]: isBasePath(pathname, '/invitecodes'),
                  })}
                >
                  <div className={s.linkContainer}>
                    <i className="material-icons">local_activity</i>
                    <span>Invites</span>
                  </div>
                </a>
              )}
            <a
              tabIndex={0}
              href="/developers"
              onKeyUp={(e) => handleKeyPress('/developers', e)}
              onClick={(e) => handleClick('/developers', e)}
              className={cx(s.link, {
                [s.active]: isBasePath(pathname, '/developers'),
              })}
            >
              <div className={s.linkContainer}>
                <i className="material-icons">toggle_off</i>
                <span>Developers</span>
              </div>
            </a>
          </>
        )}
        {isLoadingCompleted && !isVerified && (
          <>
            <a
              tabIndex={0}
              href="/login"
              role="button"
              onKeyUp={(e) => handleKeyPress('/login', e)}
              onClick={(e) => handleClick('/login', e)}
              className={cx(s.link, {
                [s.active]: isBasePath(pathname, '/login'),
              })}
            >
              <div className={s.linkContainer}>
                <i className="material-icons">lock_open</i>
                <span>Login</span>
              </div>
            </a>
            <a
              tabIndex={0}
              href="/signup"
              role="button"
              onKeyUp={(e) => handleKeyPress('/signup', e)}
              onClick={(e) => handleClick('/signup', e)}
              className={cx(s.link, {
                [s.active]: isBasePath(pathname, '/signup'),
              })}
            >
              <div className={s.linkContainer}>
                <i className="material-icons">person_add</i>
                <span>Sign up</span>
              </div>
            </a>
          </>
        )}
      </div>
      <div className={s.bottomMenu}>
        {isFeatureGnosisPayPromotion && isLoadingCompleted && (
          <GnosisPromotion />
        )}
        <a
          tabIndex={0}
          href="/help"
          onKeyUp={(e) => handleKeyPress('/help', e)}
          onClick={(e) => handleClick('/help', e)}
          className={cx(s.link, {
            [s.active]: isBasePath(pathname, '/help'),
          })}
        >
          <div className={s.linkContainer}>
            <i className="material-icons">chat</i>
            <span>Support</span>
          </div>
        </a>

        <a
          tabIndex={0}
          className={s.link}
          href="https://monerium.com/policies/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          <div className={s.linkContainer}>
            <i className="material-icons">verified_user</i>
            <span>Policies</span>
            <i className={cx('material-icons', s.external)}>launch</i>
          </div>
        </a>
        {isVerified && (
          <a
            tabIndex={0}
            href="/logout"
            role="button"
            onKeyUp={(e) => handleKeyPress('/logout', e)}
            onClick={(e) => handleClick('/logout', e)}
            className={cx(s.link, {
              [s.active]: isBasePath(pathname, '/logout'),
            })}
          >
            <div className={s.linkContainer}>
              <i className="material-icons">https</i>
              <span>Logout</span>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default SidebarComponent;
