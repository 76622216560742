import bgImage from './bg.png';

const GnosisPayPromotion = () => {
  return (
    <a href="/promotions/gnosispay">
      <div
        style={{
          background: '#1a1916',
          backgroundImage: `url('${bgImage}')`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '0 0',
          backgroundSize: 'contain',
          padding: '15px',
          margin: '8px 20px',
          fontSize: '1.1rem',
          color: '#fff',
          borderRadius: '13px',
        }}
      >
        <img
          src="/assets/promotions/gnosispay.png"
          alt="Gnosis Pay Card"
          style={{
            width: '90%',
            borderRadius: '8px',
            marginRight: '10px',
          }}
        />
        <p
          style={{
            fontSize: '.8rem',
            marginTop: '8px',
            marginBottom: '16px',
          }}
        >
          Get a free card for a limited time
        </p>
        <span
          style={{
            fontSize: '.8rem',
            borderRadius: '8px',
            backgroundColor: 'rgb(205, 223, 82)',
            color: '#000',
            padding: '8px 16px',
            fontWeight: 'bold',
            display: 'block',
            textAlign: 'center'
          }}
        >
          Get my card
        </span>
      </div>
    </a>
  );
};
export default GnosisPayPromotion;
