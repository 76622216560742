import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import s from './Announcement.less';

const Announcement = ({
  announcement,
  link,
}: {
  announcement?: string;
  link?: string;
}) => {
  useStyles(s);
  const [isBannerVisible, setBannerVisible] = useState(false);
  const encodedKey = Buffer.from(
    (announcement as string) + (link as string),
  ).toString('base64');

  useEffect(() => {
    const data = localStorage.getItem(`monerium-announcement-${encodedKey}`);
    if (data === 'hide') {
      setBannerVisible(false);
    } else {
      setBannerVisible(true);
    }
  }, []);

  const hideBanner = () => {
    setBannerVisible(false);
    localStorage.setItem(`monerium-announcement-${encodedKey}`, 'hide');
  };

  if (!announcement) return null;

  return isBannerVisible ? (
    <div id="alert-banner" className={s.alertBanner}>
      <span
        className={s.closeBtn}
        onClick={hideBanner}
        onKeyDown={(event) => {
          // 'Enter' or 'Space' key to hide banner
          if (event.key === 'Enter' || event.key === ' ') {
            hideBanner();
          }
        }}
        role="button"
        tabIndex={0}
      >
        &times;
      </span>
      {announcement && (
        <span>
          <strong>Note! </strong>
          {announcement}

          {link && (
            <>
              {' '}
              <a
                className={s.learnMore}
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                Learn more.
              </a>
            </>
          )}
        </span>
      )}
    </div>
  ) : null;
};
export default Announcement;
