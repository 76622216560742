import rest from 'services/rest';

export default {
  test: () => {
    if (!document || !document.cookie) return false;
    document.cookie = 'testCookie=1';
    return document.cookie.indexOf('testCookie') !== -1;
  },
  get: () => document.cookie.indexOf('cookie_notice') !== -1,
  accept: () => rest.get('/cookienotice'),
};
