// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twfyx,.OIiQ1{text-align:center}.twfyx{color:#424953;font-size:14px;font-size:.875rem;font-weight:300;margin-bottom:30px}.huc4V{margin:0 auto;max-width:400px;padding:0 30px 30px}.kI1_Z{color:#424953;font-size:14px;font-size:.875rem;font-weight:300;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructions": `twfyx`,
	"title": `OIiQ1`,
	"form": `huc4V`,
	"resetPassword": `kI1_Z`
};
module.exports = ___CSS_LOADER_EXPORT___;
