
    var refs = 0;
    var css = require("!!../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-ab4e4ffa19.zip/node_modules/postcss-loader/dist/cjs.js!../../../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-39eebd6c75.zip/node_modules/less-loader/dist/cjs.js!./Banner.less");
    var css_default = css.default || css;
    var insertCss = require("!../../../../../../.yarn/__virtual__/isomorphic-style-loader-react18-virtual-602426e3cb/0/cache/isomorphic-style-loader-react18-npm-5.9.2-c4b7b637bf-2afcfd784c.zip/node_modules/isomorphic-style-loader-react18/insertCss.js");
    var content = typeof css_default === 'string' ? [[module.id, css_default, '']] : css_default;

    exports = module.exports = css_default.locals || {};
    exports._getContent = function() { return content; };
    exports._getCss = function() { return '' + css_default; };
    exports._insertCss = function(options) { return insertCss(content, options) };

    // Hot Module Replacement
    // https://webpack.github.io/docs/hot-module-replacement
    // Only activated in browser context
    if (module.hot && typeof window !== 'undefined' && window.document) {
      var removeCss = function() {};
      module.hot.accept("!!../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-ab4e4ffa19.zip/node_modules/postcss-loader/dist/cjs.js!../../../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-39eebd6c75.zip/node_modules/less-loader/dist/cjs.js!./Banner.less", function() {
        css = require("!!../../../../../../.yarn/__virtual__/css-loader-virtual-fd0f9164f3/0/cache/css-loader-npm-6.11.0-d945f9f4c0-9e3665509f.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].rules[2]!../../../../../../.yarn/__virtual__/postcss-loader-virtual-efe46bb6b5/0/cache/postcss-loader-npm-6.2.1-45828eb0de-ab4e4ffa19.zip/node_modules/postcss-loader/dist/cjs.js!../../../../../../.yarn/__virtual__/less-loader-virtual-e54b7d100c/0/cache/less-loader-npm-10.2.0-9c6619a5c0-39eebd6c75.zip/node_modules/less-loader/dist/cjs.js!./Banner.less");
        content = typeof css === 'string' ? [[module.id, css, '']] : css;
        removeCss = insertCss(content, { replace: true });
      });
      module.hot.dispose(function() { removeCss(); });
    }
  