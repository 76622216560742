import { alertFailure } from '../../notification/Alert/actions';
import constants from './constants';
import service from './service';

const test = () => {
  const request = () => ({ type: constants.TEST_REQUEST });
  const success = (cookiesEnabled) => ({
    type: constants.TEST_SUCCESS,
    cookiesEnabled,
  });
  const failure = () => ({ type: constants.TEST_FAILURE });
  return (dispatch) => {
    dispatch(request());
    const cookiesEnabled = service.test();
    if (cookiesEnabled) dispatch(success());
    else dispatch(failure());
  };
};

const get = () => {
  const request = () => ({ type: constants.GET_REQUEST });
  const success = (cookiesAccepted) => ({
    type: constants.GET_SUCCESS,
    cookiesAccepted,
  });
  const failure = () => ({ type: constants.GET_FAILURE });
  return (dispatch) => {
    dispatch(request());
    const cookiesAccepted = service.get();
    if (cookiesAccepted) dispatch(success(cookiesAccepted));
    else dispatch(failure());
  };
};

const accept = () => {
  const request = () => ({ type: constants.ACCEPT_REQUEST });
  const success = () => ({
    type: constants.ACCEPT_SUCCESS,
  });
  const failure = () => ({ type: constants.ACCEPT_FAILURE });
  return (dispatch) => {
    dispatch(request());
    service
      .accept()
      .then(() => {
        const cookiesAccepted = service.get();
        if (cookiesAccepted) dispatch(success());
        else dispatch(failure());
      })
      .catch((err) => dispatch(alertFailure(err, failure)));
  };
};

export default {
  test,
  get,
  accept,
};
